import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil, timer } from 'rxjs';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Router } from '@angular/router';

@Component({
   selector: 'app-upgrade-popup',
   templateUrl: './upgrade-popup.component.html',
   styleUrls: ['./upgrade-popup.component.scss'],
   animations: [
      trigger('fade', [
         state('void', style({ opacity: 0 })),
         state('*', style({ opacity: 1 })),
         transition('void <=> *', [
            animate('.3s ease-in-out')
         ]),
      ]),
   ],
})
export class UpgradePopupComponent implements OnInit, OnDestroy {
   private destroy$ = new Subject<void>();
   private intervalId: any; 
   private scrollDelayTimeoutId: any; 

   @Input() activefeature!: 'report' | 'portfolio' | 'buySell' | 'brokerage';
   features: Array<'report' | 'portfolio' | 'buySell' | 'brokerage'> = ['report', 'portfolio', 'buySell', 'brokerage'];

   constructor(
      public activeUpgradeModal: NgbActiveModal,
      private router: Router,
   ) {}

   ngOnInit(): void {
      if (!this.activefeature) {
         this.activefeature = 'report';
      }

      this.scrollDelayTimeoutId = setTimeout(() => {
         this.startAutoScroll();
      }, 3000);
   }

   ngOnDestroy(): void {
      this.stopAutoScroll();
      if (this.scrollDelayTimeoutId) {
         clearTimeout(this.scrollDelayTimeoutId);
      }
      this.destroy$.next();
      this.destroy$.complete();
   }

   startAutoScroll() {
      this.intervalId = setInterval(() => {
         this.nextFeature();
      }, 3000);
   }

   stopAutoScroll() {
      if (this.intervalId) {
         clearInterval(this.intervalId);
      }
   }

   nextFeature() {
      const currentIndex = this.features.indexOf(this.activefeature);
      this.activefeature = this.features[(currentIndex + 1) % this.features.length];
   }

   setActiveFeature(feature: 'report' | 'portfolio' | 'buySell' | 'brokerage') {
      this.activefeature = feature;
      this.stopAutoScroll(); 
      this.startAutoScroll();
   }

   navigateToPricing() {
      this.activeUpgradeModal.close();
      timer(10).pipe(takeUntil(this.destroy$)).subscribe(() => {
         this.router.navigate(['/pricing']);
      });
   }
}


