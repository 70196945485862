import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseCloudFunctionsService {

  constructor(private http: HttpClient, public authFire: AngularFireAuth) {}

  public async getIdToken() {
    const user: any = await this.authFire.currentUser;
    const token = await user?.getIdToken();
    return token;
  }


  public async getUserBackendToken() {
   return await lastValueFrom(this.http.post(environment.firebaseCloudFunctionsUrl+'/getBackendToken', { token: await this.getIdToken() }))
  }

  public async getUserToken(email: string, password: string) {
   return await lastValueFrom(this.http.post(environment.firebaseCloudFunctionsUrl+'/getUserToken', { email: email, password: password }))
  }

  public async userRegistration(firstName: string, lastName: string, email: string, password: string) {
   return await lastValueFrom(this.http.post(environment.firebaseCloudFunctionsUrl+'/userRegistration', { firstname: firstName, lastname: lastName, email: email, password: password }))
  }

  public googleRegistration(value: any) {
   return this.http.post(environment.firebaseCloudFunctionsUrl+'/userRegistrationByGoogle', value)
  }

  public activateCode(identifier: string, activationCode: string) {
   return this.http.post(environment.firebaseCloudFunctionsUrl+'/userRegistrationWithEmail',
      {identifier: identifier, activationCode: activationCode});
  }

  public async usersRegistration(data: any) {
   return await lastValueFrom(this.http.post(environment.firebaseCloudFunctionsUrl+'/userRegistrationWithEmailSubmitUserData', data));
  }

  public async updateUserData(userData: any) {
    userData = { ...userData, token: await this.getIdToken() }
    return await lastValueFrom(this.http.post(environment.firebaseCloudFunctionsUrl+'/updateUserData', userData));
  }

  public async changePassword (passwordData:any) {
    passwordData = { ...passwordData, token: await this.getIdToken() }
    return await lastValueFrom(this.http.post(environment.firebaseCloudFunctionsUrl+'/changePassword', passwordData));
  }

  public async forgotPasswordChange (forgotpasswordData:any) {
    return await lastValueFrom(this.http.post(environment.firebaseCloudFunctionsUrl+'/forgotPassword', forgotpasswordData));
  }

}
