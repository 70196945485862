import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { BehaviorSubject, of } from 'rxjs';
import { InvestmentChecklistVariableModel } from 'src/app/models/investment-checklist-variables.model';
import { MarketUtilsService } from './market-utils.service';
import { WebsiteStats } from 'src/app/models/website-stats/website.stats.modal';
// import { MarketUtilsService } from './market-utils.service';

@Injectable({
   providedIn: 'root',
})
export class TypesenseService {
   private dividendYieldData = new BehaviorSubject<any>(null);
   public dividendYieldData$ = this.dividendYieldData.asObservable();

   private websiteStats$!: Observable<WebsiteStats>;
   private portfolioStats$!: Observable<any>

   constructor(
      private auth: AuthService,
      private http: HttpClient,
      private marketUtilsService: MarketUtilsService
   ) {}

   searchStocksAndEtfs(
      name: string,
      stockCountries: any = [],
      etfCountries: any = [],
      userCountry: string | null
   ): any {
      if (name === '') {
         return of([]);
      }

      const searches = [
         {
            collection: 'company_profile_collection',
            q: name,
            query_by: 'name,ticker',
            prioritize_token_position: true,
            sort_by: '_text_match:desc,tickerIsMain:desc,usdMarketCap:desc',
            query_by_weights: '1,2',
            filter_by: `status:=PUBLISH&&country:=[${stockCountries}]`,
         },
         {
            collection: 'etf_profile_collection_alias',
            q: name,
            query_by: 'name,symbol',
            prioritize_token_position: true,
            sort_by: '_text_match:desc,aum:desc',
            query_by_weights: '1,2',
            filter_by: `domicile:=[${etfCountries}]`,
         },
      ];

      return this.http
         .post<any>(environment.typeSense.url + '/multi_search', { searches })
         .pipe(
            map((item) => {
               const results: any = item?.results;
               const ret: any[] = [];

               results.forEach((item: any) => {
                  const data: any = item.hits;
                  if (data) {
                     if (
                        item.request_params?.collection_name.includes(
                           'company_profile_collection'
                        )
                     ) {
                        this.processStockData(data, ret, name);
                     } else {
                        this.processEtfData(data, ret, name);
                     }
                  }
               });

               return ret.sort((a, b) => b.textMatch - a.textMatch);
            })
         );
   }

   private processStockData(data: any, ret: any[], name: string) {
      data.forEach((x: any) => {
         const doc = x.document;
         const tickerSegments = doc.ticker.split('.');
         let textMatch = x.text_match;
         if (tickerSegments.includes(name.toUpperCase())) {
            textMatch += textMatch;
         }
         ret.push({
            canAddToWatchlist: true,
            companyName: doc.name,
            countryName: doc.country,
            exchange: doc.exchange,
            flag: doc.country.toLowerCase(),
            logo: doc.logo,
            identifier: doc.identifier,
            stockName: doc.ticker,
            type: 'STOCK',
            textMatch: textMatch,
            isin: doc.isin,
            currency: doc.currency,
            complianceStatus: doc.shariahCompliantStatus,
            ranking: doc.compliantRanking,
            change: doc.change,
            price: doc.currentPrice,
         });
      });
   }

   private processEtfData(data: any, ret: any[], name: string) {
      data.forEach((x: any) => {
         const doc = x.document;
         const tickerSegments = doc.symbol.split('.');
         let textMatch = x.text_match;
         if (tickerSegments.includes(name)) {
            textMatch += textMatch;
         }
         ret.push({
            canAddToWatchlist: true,
            companyName: doc.name,
            countryName: doc.domicile,
            exchange: doc.exchange,
            flag: doc.domicile.toLowerCase(),
            identifier: doc.identifier,
            stockName: doc.symbol,
            type: 'ETF',
            textMatch: textMatch,
            currency: doc.navCurrency,
            complianceStatus: doc.shariahStates,
            ranking: doc.ranking,
            change: doc.change,
            price: doc.currentPrice,
         });
      });
   }

   public searchStockTypeSense(
      name: string,
      stockCountries: any = [],
      etfCountries: any = [],
      userCountry: string | null
   ): any {
      return this.http
         .post<any>(environment.typeSense.url + '/multi_search', {
            searches: [
               {
                  collection: 'company_profile_collection',
                  q: name,
                  query_by: 'name,ticker',
                  prioritize_token_position: true,
                  // "prioritize_exact_match": true,
                  sort_by:
                     '_text_match:desc,tickerIsMain:desc,usdMarketCap:desc',
                  query_by_weights: '1,2',
                  // text_match_type: 'max_weight',
                  filter_by: `status:=PUBLISH&&country:=[${stockCountries}]`,
               },
               // {
               //    collection: 'company_profile_collection',
               //    q: name,
               //    query_by: 'name,ticker',
               //    prioritize_token_position: true,
               //    // "prioritize_exact_match": true,
               //    sort_by:
               //       '_text_match:desc,tickerIsMain:desc,usdMarketCap:desc',
               //    query_by_weights: '1,2',
               //    // text_match_type: 'max_weight',
               //    num_typos: '0',
               //    filter_by: `status:=PUBLISH${
               //       userCountry ? `&&country:=[${userCountry}]` : ''
               //    }`,
               // },
               {
                  collection: 'etf_profile_collection_alias',
                  q: name,
                  query_by: 'name,symbol',
                  prioritize_token_position: true,
                  // "prioritize_exact_match": true,
                  sort_by: '_text_match:desc,aum:desc',
                  query_by_weights: '1,2',
                  // text_match_type: 'max_weight',
                  filter_by: `domicile:=[${etfCountries}]`,
               },
            ],
         })
         .pipe(
            map((item) => {
               const results: any = item?.results;

               const ret: any[] = [];

               results.forEach((item: any) => {
                  const data: any = item.hits;
                  if (data) {
                     if (
                        item.request_params?.collection_name.includes(
                           'company_profile_collection'
                        )
                     ) {
                        data.forEach((x: any) => {
                           const doc = x.document;
                           const tickerSegments = doc.ticker.split('.');
                           let textMatch = x.text_match;
                           if (tickerSegments.includes(name.toUpperCase())) {
                              textMatch += textMatch;
                           }
                           ret.push({
                              canAddToWatchlist: true,
                              companyName: doc.name,
                              countryName: doc.country,
                              exchange: doc.exchange,
                              flag: doc.country.toLowerCase(),
                              identifier: doc.identifier,
                              stockName: doc.ticker,
                              type: 'STOCK',
                              textMatch: textMatch,
                              isin: doc.isin,
                              currency: doc.currency,
                              complianceStatus: doc.shariahCompliantStatus,
                              ranking: doc.compliantRanking,
                           });
                        });
                     } else {
                        data.forEach((x: any) => {
                           const doc = x.document;
                           const tickerSegments = doc.symbol.split('.');
                           let textMatch = x.text_match;
                           if (tickerSegments.includes(name)) {
                              textMatch += textMatch;
                           }
                           ret.push({
                              canAddToWatchlist: true,
                              companyName: doc.name,
                              countryName: doc.domicile,
                              exchange: doc.exchange,
                              flag: doc.domicile.toLowerCase(),
                              identifier: doc.identifier,
                              stockName: doc.symbol,
                              type: 'ETF',
                              textMatch: textMatch,
                              currency: doc.navCurrency,
                              complianceStatus: doc.shariahStates,
                              ranking: doc.ranking,
                           });
                        });
                     }
                  }
               });
               return ret.sort((a, b) => b.textMatch - a.textMatch);
            })
         );
   }

   public searchOnlyStockTypeSense(name: string): any {
      return this.http
         .post<any>(environment.typeSense.url + '/multi_search', {
            searches: [
               {
                  collection: 'company_profile_collection',
                  q: name,
                  query_by: 'name,ticker',
                  prioritize_token_position: true,
                  sort_by:
                     '_text_match:desc,tickerIsMain:desc,usdMarketCap:desc',
                  query_by_weights: '1,1',
                  filter_by:
                     'country:[US,UK,CA,FR,SA,IN,ID,MY,SG,ZA,EG,QA,BH,AE,ES,NL]&&status:=PUBLISH',
               },
            ],
         })
         .pipe(
            map((item) => {
               const results: any = item?.results;

               const ret: any[] = [];

               results.forEach((item: any) => {
                  const data: any = item.hits;
                  if (
                     item.request_params?.collection_name.includes(
                        'company_profile_collection'
                     )
                  ) {
                     data.forEach((x: any) => {
                        const doc = x.document;
                        ret.push({
                           canAddToWatchlist: true,
                           companyName: doc.name,
                           countryName: doc.country,
                           exchange: doc.exchange,
                           flag: doc.country.toLowerCase(),
                           identifier: doc.identifier,
                           stockName: doc.ticker,
                           type: 'STOCK',
                           textMatch: x.text_match,
                           status: doc.shariahCompliantStatus,
                           currency: doc.currency,
                        });
                     });
                  } else {
                     data.forEach((x: any) => {
                        const doc = x.document;
                        ret.push({
                           canAddToWatchlist: true,
                           companyName: doc.name,
                           countryName: doc.domicile,
                           exchange: doc.exchange,
                           flag: doc.domicile.toLowerCase(),
                           identifier: doc.identifier,
                           stockName: doc.symbol,
                           type: 'ETF',
                           textMatch: x.text_match,
                           status: doc.shariahCompliantStatus,
                           currency: doc.currency,
                        });
                     });
                  }
               });
               return ret.sort((a, b) => b.textMatch - a.textMatch);
            })
         );
   }

   public getPopularStocksTypeSense(
      page: number,
      sector: string,
      industryList: string[],
      compliance: string[],
      country: string,
      per_page: number = 15
   ): Observable<any> {
      const filterBy = [];
      const symbols: any[] = [];
      const sortBy = ['usdMarketCap:desc', 'exchangeMarketCap:desc'];
      filterBy.push('status:=PUBLISH');
      filterBy.push('exchange:!=OTC');
      filterBy.push('tickerIsMain:=true');
      if (country) {
         filterBy.push('country:=' + country);
         // if(country === 'US') {
         //    sortBy.push('usdMarketCap:desc')
         // }
      }
      if (sector) {
         filterBy.push('musaffaSectorName:=' + sector);
      }
      if (industryList.length > 0) {
         filterBy.push('musaffaIndustryName:[' + industryList.join(',') + ']');
      }

      // sortBy.push('usdMarketCap:desc')

      // const groupBy = 'group_by=mainTicker&group_limit=1&'
      // return this.http.get<any>(`${environment.typeSense.url}/collections/company_profile_collection/documents/search?${groupBy}sort_by=${sortBy.join(',')}&page=${page}&per_page=50&q=*&filter_by=${filterBy.join('&&')}`)
      //    .pipe(map((item) => {
      //    const items: any[] = []
      //    const data: any = item.grouped_hits
      //    data.forEach((x: any) => {
      //       const doc = x.hits[0].document
      //       symbols.push(doc.isin)
      //       items.push({
      //          companyName: doc.name,
      //          currency: doc.currency,
      //          exchange: doc.exchange,
      //          exchangeMarketCap: doc.exchangeMarketCap,
      //          identifier: doc.identifier,
      //          industry: doc.musaffaIndustryName,
      //          isin: doc.isin,
      //          logo: doc.logo,
      //          marketCapitalization: doc.marketCapitalization,
      //          stockName: doc.ticker,
      //          sector: doc.musaffaSectorName,
      //       })
      //    })
      //    const number_of_pages = Math.ceil(item.found/item.request_params.per_page)
      //    return {
      //       currentPage: item.page,
      //       hasNext: item.page < number_of_pages,
      //       hasPrevious: item.page > 1,
      //       itemCount: item.request_params.per_page,
      //       pageQuantity: number_of_pages,
      //       totalCount: item.found,
      //       items: items,
      //       symbols: symbols
      //    }
      // }))
      return this.http
         .get<any>(
            `${
               environment.typeSense.url
            }/collections/company_profile_collection/documents/search?sort_by=${sortBy.join(
               ','
            )}&page=${page}&per_page=${per_page}&q=*&filter_by=${filterBy.join(
               '&&'
            )}&&shariahCompliantStatus:=${compliance}`
         )
         .pipe(
            map((item) => {
               const items: any[] = [];
               const data: any = item.hits;
               data.forEach((x: any) => {
                  const doc = x.document;
                  symbols.push(doc.isin);
                  items.push({
                     companyName: doc.name,
                     currency: doc.currency,
                     exchange: doc.exchange,
                     exchangeMarketCap: doc.exchangeMarketCap,
                     identifier: doc.identifier,
                     industry: doc.musaffaIndustryName,
                     isin: doc.isin,
                     logo: doc.logo,
                     marketCapitalization: doc.marketCapitalization,
                     stockName: doc.ticker,
                     sector: doc.musaffaSectorName,
                  });
               });
               const number_of_pages = Math.ceil(
                  item.found / item.request_params.per_page
               );
               return {
                  currentPage: item.page,
                  hasNext: item.page < number_of_pages,
                  hasPrevious: item.page > 1,
                  itemCount: item.request_params.per_page,
                  pageQuantity: number_of_pages,
                  totalCount: item.found,
                  items: items,
                  symbols: symbols,
                  shariahCompliantStatus: item.shariahCompliantStatus,
               };
            })
         );
   }

   private async getStocksExchangeData(countryCode: string, type: string) {
      const countriesData = await this.marketUtilsService.fetchCountries();
      if (countriesData) {
         var currentCountryObj = countriesData.find(
            (obj: any) => obj.countryCode == countryCode
         ) ?? { stockExchanges: [], etfExchanges: [] };
         var exchangeArr = [];
         if (type === 'stock') {
            exchangeArr = currentCountryObj.stockExchanges;
         } else exchangeArr = currentCountryObj.etfExchanges;
         return exchangeArr;
      }
      return [];
   }

   public async getPopularStocksTypeSenseV2(
      page: number,
      sector: string,
      exchange: string[],
      industryList: string[],
      compliance: string[],
      complianceScore: string[],
      country: string,
      per_page: number = 15,
      recommededStatus: string[],
      sortCol: any,
      sortOrder: string,
      sort?: string,
      showPrimary: boolean = true
   ): Promise<any> {
      const filterBy = [];
      const symbols: any[] = [];
      const sortBy = ['usdMarketCap:desc', 'exchangeMarketCap:desc'];

      filterBy.push('status:=PUBLISH');
      if (showPrimary) {
         filterBy.push('tickerIsMain:=true');
      }
      if (country) {
         filterBy.push('country:=' + country?.toUpperCase());
      }
      if (exchange && exchange.length) {
         filterBy.push('exchange:=[' + exchange.join(',') + ']');
      } else {
         filterBy.push('exchange:!=OTC');
         if (country?.toUpperCase() === 'US') {
            filterBy.push('exchange:=[NYSE,NASDAQ]');
         }
         // const list: any = await this.getStocksExchangeData(country, 'stock')
         // filterBy.push('exchange:=[' + list.join(',') + ']');
      }
      // console.log("sector "+sector)
      if (sector?.trim()?.length) {
         filterBy.push(`musaffaSectorName:=\`${sector?.trim()}\``);
      }
      if (sort) {
         sortBy.unshift(sort);
      }
      // if (industryList && industryList.length > 0) {
      //    let formattedArr = industryList.map((item) => '`' + item + '`');
      //    let str = `musaffaIndustryName:=[${formattedArr.join(',')}]`;
      //    filterBy.push(str);
      // }

      if (compliance && compliance.length) {
         if (compliance.includes('')) {
            filterBy.push(
               "shariahCompliantStatus:=['COMPLIANT', 'NON_COMPLIANT', 'QUESTIONABLE']"
            );
         } else if (!compliance.includes('')) {
            let formattedArr = compliance.map((item: any) =>
               item ? '`' + item + '`' : ''
            );
            let str = `shariahCompliantStatus:=[${formattedArr.join(',')}]`;
            filterBy.push(str);
         }
      } else {
         filterBy.push(
            "shariahCompliantStatus:=['COMPLIANT', 'NON_COMPLIANT', 'QUESTIONABLE']"
         );
      }

      // if (compliance && compliance.length) {
      //    let formattedArr;

      //    if(compliance.includes('')) {
      //       formattedArr = compliance.map((item : any) => item ? '`' + item + '`' : '')
      //       let str = `shariahCompliantStatus:=[${formattedArr.join(',')}]`;
      //       filterBy.push(str);
      //    } else if(!compliance.includes('')) {
      //       formattedArr = compliance.map((item : any) => item ? '`' + item + '`' : '')
      //       let str = `shariahCompliantStatus:=[${formattedArr.join(',')}]`;
      //       filterBy.push(str);
      //    } else {
      //       formattedArr = [];
      //    }

      // }

      if (complianceScore && complianceScore.length) {
         filterBy.push('compliantRanking:=[' + complianceScore.join(',') + ']');
      }

      if (recommededStatus && recommededStatus.length) {
         filterBy.push(
            'recommendationWeightedAverage:=[' +
               recommededStatus.join(',') +
               ']'
         );
      }

      if (sortCol && sortOrder) {
         sortBy.unshift(`${sortCol}:${sortOrder}`);
      }

      let queryParams = new HttpParams()
         .set('sort_by', sortBy.join(','))
         .set('page', page)
         .set('per_page', per_page)
         .set('q', '*')
         .set('filter_by', filterBy.join('&&'));

      return await lastValueFrom(
         this.http
            .get<any>(
               `${environment.typeSense.url}/collections/company_profile_collection/documents/search`,
               { params: queryParams }
            )
            .pipe(
               map((item) => {
                  const items: any[] = [];
                  const data: any = item.hits;
                  data.forEach((x: any) => {
                     const doc = x.document;
                     symbols.push(doc.isin);
                     items.push({
                        companyName: doc.name,
                        currency: doc.currency,
                        exchange: doc.exchange,
                        exchangeMarketCap: doc.exchangeMarketCap,
                        identifier: doc.identifier,
                        industry: doc.musaffaIndustryName,
                        isin: doc.isin,
                        logo: doc.logo,
                        marketCapitalization: doc.marketCapitalization,
                        stockName: doc.ticker,
                        sector: doc.musaffaSectorName,
                        percentChange: doc.percentChange,
                        currentPrice: doc.currentPrice,
                        recommededStatus: doc.recommendationWeightedAverage,
                     });
                  });
                  const number_of_pages = Math.ceil(
                     item.found / item.request_params.per_page
                  );
                  return {
                     currentPage: item.page,
                     hasNext: item.page < number_of_pages,
                     hasPrevious: item.page > 1,
                     itemCount: item.request_params.per_page,
                     pageQuantity: number_of_pages,
                     totalCount: item.found,
                     items: items,
                     symbols: symbols,
                  };
               })
            )
      );
   }
   public async getStockComplianceFromSymbols(symbols: any) {
      const filterBy = symbols.length > 0 ? `filter_by=isin:[${symbols.join(',')}]` : '';
      return await lastValueFrom(
         this.http
            .get<any>(
               `${
                  environment.typeSense.url
               }/collections/compliance_collection_nova/documents/search?per_page=250&x-typesense-api-key=${
                  environment.typeSense.searchApiKey
               }&q=*&${filterBy}`
            )
            .pipe(
               map((item) => {
                  const data: any = item.hits;
                  let ret: any = {};
                  data.forEach((x: any) => {
                     const doc = x.document;
                     ret[doc.isin] = {
                        status: doc.status,
                        ranking: doc.ranking,
                     };
                  });

                  return ret;
               })
            )
      );
   }

   async getEtfsCollection<T>(
      country: string,
      page: number,
      per_page: number,
      sort_by: string,
      filter_by: any
   ): Promise<T> {
      let params = new HttpParams()
         .set('q', '*')
         .set('country', country)
         .set('page', page)
         .set('per_page', per_page)
         .set('sort_by', sort_by)
         .set('filter_by', filter_by);

      return await lastValueFrom(
         this.http.get<T>(
            `${environment.typeSense.url}/collections/etf_profile_collection_alias/documents/search`,
            { params }
         )
      );
   }

   public async getPopularEtfTypeSense(
      country: string,
      page: number,
      filterData: any,
      per_page: number = 15,
      sortCol: any,
      sortOrder: string
   ): Promise<any> {
      // console.log(sortCol);
      // console.log(sortOrder);
      const filterBy = [];
      const sortBy = ['aum:desc'];
      // console.log(filterData);


      if (filterData != null) {
         if (filterData.exchange && filterData.exchange.length) {
            filterBy.push(
               'exchange:=[' + filterData.exchange.join(',') + ']'
            );
         } else {
            const list: any = await this.getStocksExchangeData(country, 'etf');
            filterBy.push('exchange:=[' + list.join(',') + ']');
         }
         if (filterData.assetClass && filterData.assetClass.length) {
            filterBy.push(
               'assetClass:=[' + filterData.assetClass.join(',') + ']'
            );
         } else {
            // let classData = filterData.etfClass.map((item: any) => item.key);
            // filterBy.push('assetClass:=[' + classData.join(',') + ']');
         }
         if (filterData.shariahStates && filterData.shariahStates.length) {
            // let formattedArr = compliance.map((item)=>item ? "`"+item+"`" : '')
            // let str = `shariahCompliantStatus:=[${formattedArr.join(',')}]`
            // filterBy.push(str)

            let formattedArr;

            if (filterData.shariahStates.includes('')) {
               formattedArr = filterData.shariahStates.map((item: any) =>
                  item ? '`' + item + '`' : ''
               );
               let str = `shariahStates:=[${formattedArr.join(',')}]`;
               filterBy.push(str);
            } else if (!filterData.shariahStates.includes('')) {
               formattedArr = filterData.shariahStates.map((item: any) =>
                  item ? '`' + item + '`' : ''
               );
               let str = `shariahStates:=[${formattedArr.join(',')}]`;
               filterBy.push(str);
            } else {
               formattedArr = [];
            }
         }

         if (filterData.ranking && filterData.ranking.length) {
            filterBy.push('ranking:=[' + filterData.ranking.join(',') + ']');
         }

         if (sortCol && sortOrder) {
            sortBy.unshift(`${sortCol}:${sortOrder}`);
         }
      }
      //
      let queryParams = new HttpParams()
         .set('sort_by', sortBy.join(','))
         .set('page', page)
         .set('per_page', per_page)
         .set('q', '*')
         .set('filter_by', filterBy.join('&&'));
      return await lastValueFrom(
         this.http
            .get<any>(
               `${environment.typeSense.url}/collections/etf_profile_collection_alias/documents/search`,
               { params: queryParams }
            )
            .pipe(
               map((item) => {
                  const items: any[] = [];
                  const data: any = item.hits;
                  data.forEach((x: any) => {
                     const doc = x.document;

                     items.push({
                        assetClass: doc.assetClass,
                        aum: doc.aum,
                        avoid: doc.avoid,
                        cusip: doc.cusip,
                        description: doc.description,
                        domicile: doc.domicile,
                        etfCompany: doc.etfCompany,
                        exchange: doc.exchange,
                        expenseRatio: doc.expenseRatio,
                        halal: doc.halal,
                        identifier: doc.identifier,
                        inceptionDate: doc.inceptionDate,
                        investmentSegment: doc.investmentSegment,
                        isin: doc.isin,
                        mainSymbol: doc.mainSymbol,
                        name: doc.name,
                        nav: doc.nav,
                        navCurrency: doc.navCurrency,
                        notHalal: doc.notHalal,
                        numberOfHoldings: doc.numberOfHoldings,
                        shariahStates: doc.shariahStates,
                        symbol: doc.symbol,
                        trackingIndex: doc.trackingIndex,
                        unCovered: doc.unCovered,
                        ranking: doc.ranking,

                        status: null, //
                        price: null, //
                        priceChange: null, //
                        priceChangePercent: null, //
                        lastPrice: null, //
                     });
                  });
                  const number_of_pages = Math.ceil(
                     item.found / item.request_params.per_page
                  );
                  return {
                     currentPage: item.page,
                     hasNext: item.page < number_of_pages,
                     hasPrevious: item.page > 1,
                     itemCount: item.request_params.per_page,
                     pageQuantity: number_of_pages,
                     totalCount: item.found,
                     items: items,
                  };
               })
            )
      );
   }

   public async getEtfFromSymbols(symbols: any) {
      symbols = symbols.filter((symbol: any) => !!(symbol?.length))
      return await lastValueFrom(
         this.http
            .get<any>(
               `${
                  environment.typeSense.url
               }/collections/etf_profile_collection_alias/documents/search?per_page=250&q=*&filter_by=symbol:[${symbols.join(
                  ','
               )}]`
            )
            .pipe(
               map((item) => {
                  const items: any = {};
                  const data: any = item.hits;
                  data.forEach((x: any) => {
                     const doc = x.document;
                     items[doc.symbol] = doc;
                  });
                  return items;
               })
            )
      );
   }

   public async getStockFromSymbols(symbols: any) {
      symbols = symbols.filter((symbol: any) => !!(symbol?.length))
      const chunkSize = 30;
      const data = [];
      const items: any = {};
      for (let i = 0; i < symbols.length; i += chunkSize) {
         const chunk = symbols.slice(i, i + chunkSize);

         let filterBy = `ticker:[${chunk.join(',')}]`;

         let queryParams = new HttpParams()
            .set('q', '*')
            .set('per_page', '250')
            .set('filter_by', filterBy);

         // return lastValueFrom(
         //    this.http.get<any>(
         //       `${environment.typeSense.url}/collections/bse_tickers/documents/search`,
         //       { params: queryParams }
         //    )
         // );

         await lastValueFrom(
            this.http
               .get<any>(
                  `${environment.typeSense.url}/collections/company_profile_collection/documents/search`,
                  { params: queryParams }
               )
               .pipe(
                  map((item) => {
                     const data: any = item.hits;
                     data.forEach((x: any) => {
                        const doc = x.document;
                        items[doc.ticker] = doc;
                     });
                     return items;
                  })
               )
         );
      }

      return items;
   }

   public getRecentlyViewed() {
      const userId = this.auth.getUserId() ?? null;
      return this.http
         .get<any>(
            `${environment.typeSense.url}/collections/user_recently_viewed/documents/search?q=*&sort_by=last_viewed_at:desc&filter_by=user_id:=${userId}&per_page=50`
         )
         .pipe(
            map((item) => {
               const items: any = {};
               const stockSymbols: any[] = [];
               const etfSymbols: any[] = [];
               const data: any = item.hits;
               data.forEach((x: any) => {
                  const doc = x.document;
                  if (doc.type === 'stock') {
                     stockSymbols.push(doc.symbol);
                     items[doc.symbol] = {
                        companyName: null,
                        // compliantRanking: 0,
                        currency: null,
                        identifier: null,
                        isin: doc.isin,
                        // shariahCompliantStatus: "QUESTIONABLE",
                        stockName: doc.symbol,
                        type: 'STOCK',
                     };
                  } else if (doc.type === 'etf') {
                     etfSymbols.push(doc.symbol);
                     items[doc.symbol] = {
                        domicile: null,
                        etfCompany: null,
                        identifier: null,
                        name: null,
                        nav: null,
                        navCurrency: null,
                        isin: doc.isin,
                        symbol: doc.symbol,
                        type: 'ETF',
                     };
                  }
               });

               return {
                  viewedItems: items,
                  stockSymbols: stockSymbols,
                  etfSymbols: etfSymbols,
               };
            })
         );
   }

   public getCompanyProfileObservable(ticker: string) {
      return this.http.get<any>(
         `${environment.typeSense.url}/collections/company_profile_collection/documents/${ticker}`
      );
   }
   public getCompanyProfile(ticker: string) {
      return this.http
         .get<any>(
            `${environment.typeSense.url}/collections/company_profile_collection/documents/${ticker}`
         )
         .pipe(
            map((item) => {
               if (item) {
                  const {
                     debt,
                     logs,
                     notHalal,
                     questionable,
                     ranking,
                     reportSource,
                     securitiesAndAssets,
                     source,
                     status,
                     totalRevenue,
                     trailing36MonAvrCap,
                     shariahCompliantStatus,
                     ...companyProfileData
                  } = item;

                  return companyProfileData;
               } else {
                  return item;
               }
            })
         );
   }

   public async getStockComplianceInfo(isin: string): Promise<any> {
      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/compliance_collection_nova/documents/${isin}?x-typesense-api-key=${environment.typeSense.searchApiKey}`
         )
      );
   }

   public getCompanyFundamentals(ticker: string): any {
      return this.http.get<any>(
         `${environment.typeSense.url}/collections/fundamentals_collection/documents/${ticker}`
      );
   }

   public async getIsUserViewedStock(isin: string): Promise<any> {
      const userId = this.auth.getUserId();
      const filterBy = [`user_id:=${userId}`, `isin:=${isin}`];
      const data = await lastValueFrom(
         this.http.get<any>(
            `${
               environment.typeSense.url
            }/collections/user_monthly_viewed/documents/search?q=*&filter_by=${filterBy.join(
               '&&'
            )}`
         )
      );
      return !!data?.hits?.length;
   }

   public getRecommendation(ticker: string): any {
      return lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/recommendation_collection/documents/${ticker}`
         )
      );
   }

   public getRelatedStocks(params: any): any {
      var url = `${environment.typeSense.url}/collections/company_profile_collection/documents/search`;
      return lastValueFrom(this.http.get<any>(url, { params: params }));
   }

   public getCompanyNews(ticker: any): any {
      var url = `${environment.typeSense.url}/collections/company_news_collection/documents/${ticker}`;
      return lastValueFrom(this.http.get<any>(url));
   }

   public stockNewArray(tickers: any): any {
      const filterBy = [`Symbol:[${tickers.join(',')}]`];
      var url = `${
         environment.typeSense.url
      }/collections/company_news_collection?search?q=*&filter_by=${filterBy.join(
         '&&'
      )}`;
      return lastValueFrom(this.http.get<any>(url));
   }

   public getPressRelease(ticker: any): any {
      var url = `${environment.typeSense.url}/collections/press_releases_collection/documents/${ticker}`;
      return lastValueFrom(this.http.get<any>(url));
   }

   public etfHoldings(symbol: string) {
      const symbols: any[] = [];
      var url = `${environment.typeSense.url}/collections/etf_holdings_collection/documents/${symbol}`;
      return this.http.get<any>(url).pipe(
         map((data) => {
            const item: any = data;
            for (let i in item.holdings) {
               if (item.holdings[i].isin) {
                  symbols.push(item.holdings[i].isin);
               }
            }
            return {
               items: item.holdings,
               totalCount: item.holdings.length,
               symbols: symbols,
            };
         })
      );
   }

   public etfGetInfo(id: string): any {
      var url = `${environment.typeSense.url}/collections/etf_profile_collection_alias/documents/${id}`;
      return this.http.get<any>(url);
   }

   public async etfComplianceInfo(id: string): Promise<any> {
      var url = `${environment.typeSense.url}/collections/etf_compliance_detailed_collection/documents/${id}`;
      return await lastValueFrom(this.http.get<any>(url));
   }

   public etfSectorExposure(id: string): Observable<any>  {
      var url = `${environment.typeSense.url}/collections/etf_sector_exposure_collection/documents/${id}`;
      return this.http.get<any>(url).pipe(
         map((data) => {
            const item: any = data;
            return item.sectorExposure;
         })
      );
   }

   public etfCountryExposure(id: string): any {
      var url = `${environment.typeSense.url}/collections/etf_country_exposure_collection/documents/${id}`;
      return this.http.get<any>(url).pipe(
         map((data) => {
            const item: any = data;
            return item.countryExposure;
         })
      );
   }

   public async getInvestorChecklist(ticker: string) {
      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/investment_checklist_collection/documents/${ticker}`
         )
      );
   }

   setData(data: any) {
      this.dividendYieldData.next(data);
   }

   public getForecastPriceTarget(ticker: string): any {
      return this.http.get<any>(
         `${environment.typeSense.url}/collections/price_target_collection/documents/${ticker}`
      );
   }

   public getForecastRevenueEstimates(ticker: string): any {
      return this.http.get<any>(
         `${environment.typeSense.url}/collections/revenue_estimates_collection/documents/${ticker}`
      );
   }

   public getForecastEarningsEstimates(ticker: string): any {
      return this.http.get<any>(
         `${environment.typeSense.url}/collections/earnings_estimates_collection/documents/${ticker}`
      );
   }

   public async forecastCollection(ticker: string) {
      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/forecasts_collection/documents/${ticker}`
         )
      );
   }

   public getBseTickers(ticker: string, code: string) {
      let filterBy: any = [];
      if (ticker) {
         filterBy = [`ticker:=${ticker}`];
      }

      if (code) {
         filterBy = [`code:=${code}`];
      }

      let queryParams = new HttpParams()
         .set('q', '*')
         .set('filter_by', filterBy);

      return lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/bse_tickers/documents/search`,
            { params: queryParams }
         )
      );
   }

   // public async getCountries() {
   // let queryParams = new HttpParams().set('per_page', '250').set('q', '*');

   // return await lastValueFrom(
   //    this.http.get<any>(
   //       `${environment.typeSense.url}/collections/market_collection/documents/search`,
   //       { params: queryParams }
   //    )
   // );
   // }

   public async getSectorIndustries() {
      let queryParams = new HttpParams().set('per_page', '250').set('q', '*');

      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/musaffa_sector_collection/documents/search`,
            { params: queryParams }
         )
      );
   }

   public async getEtfClass() {
      let queryParams = new HttpParams().set('per_page', '250').set('q', '*');

      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/musaffa_etf_class_collection/documents/search`,
            { params: queryParams }
         )
      );
   }

   public async getAcademyArticles(
      searchText: string,
      filterBy: string,
      perPage: number,
      page: number,
      sortBy: string,
      queryBy?: string
   ): Promise<any> {
      let queryParams = new HttpParams()
         .set('q', searchText)
         .set('filter_by', filterBy)
         .set('per_page', perPage)
         .set('page', page)
         .set('sort_by', sortBy);

      if (queryBy) {
         queryParams = queryParams.set('query_by', queryBy);
      }

      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/academy_articles_collection/documents/search`,
            { params: queryParams }
         )
      );
   }

   public async getYearHigh(country: string, perPage: string) {
      const filterBy = [];

      if (country) {
         filterBy.push(`country:=${country?.toUpperCase()}`);
      }
      let queryParams = new HttpParams()
         .set('q', '*')
         .set('sort_by', 'percentChange:asc')
         .set('filter_by', filterBy.join('&&'))
         .set('per_page', perPage);
      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/stock_bucket_52weekhigh/documents/search`,
            { params: queryParams }
         )
      );
   }

   public async getYearLow(country: string, perPage: string) {
      const filterBy = [];

      if (country) {
         filterBy.push(`country:=${country?.toUpperCase()}`);
      }
      let queryParams = new HttpParams()
         .set('q', '*')
         .set('sort_by', 'percentChange:asc')
         .set('filter_by', filterBy.join('&&'))
         .set('per_page', perPage);
      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/stock_bucket_52weeklow/documents/search`,
            { params: queryParams }
         )
      );
   }

   public async getMostPopularStocks(country: string, perPage: string) {
      const filterBy = [];
      if (country) {
         filterBy.push('country:=' + country?.toUpperCase());
      }
      let queryParams = new HttpParams()
         .set('q', '*')
         .set('sort_by', 'search_count:desc')
         .set('filter_by', filterBy.join('&&'))
         .set('per_page', perPage);
      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/stock_bucket_mostpopular/documents/search`,
            { params: queryParams }
         )
      );
   }

   public async getMostActiveStocks(country: string, perPage: string) {
      const filterBy = [];
      if (country) {
         filterBy.push('country:=' + country?.toUpperCase());
      }
      let queryParams = new HttpParams()
         .set('q', '*')
         .set('sort_by', 'trading_value:desc')
         .set('filter_by', filterBy.join('&&'))
         .set('per_page', perPage);
      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/stock_bucket_mostactive/documents/search`,
            { params: queryParams }
         )
      );
   }

   public async getTopGainer(country: string, perPage: string) {
      const filterBy = [];
      if (country) {
         filterBy.push('country:=' + country?.toUpperCase());
      }
      let queryParams = new HttpParams()
         .set('q', '*')
         .set('sort_by', 'percentChange:desc')
         .set('filter_by', filterBy.join('&&'))
         .set('per_page', perPage);
      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/stock_bucket_topgainers/documents/search`,
            { params: queryParams }
         )
      );
   }

   public async getTopLosers(country: string, perPage: string) {
      const filterBy = [];
      if (country) {
         filterBy.push('country:=' + country?.toUpperCase());
      }
      let queryParams = new HttpParams()
         .set('q', '*')
         .set('sort_by', 'percentChange:asc')
         .set('filter_by', filterBy.join('&&'))
         .set('per_page', perPage);
      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/stock_bucket_toplosers/documents/search`,
            { params: queryParams }
         )
      );
   }

   public async getPrices(symbols: string[]): Promise<any> {
      const chunkSize = 200;
      const items: any = {};
      for (let i = 0; i < symbols.length; i += chunkSize) {
         const chunk = symbols.slice(i, i + chunkSize);
         let newArr: any = [];
         chunk.forEach((item) => {
            let symbol = '`' + item + '`';
            newArr.push(symbol);
         });
         let filterStr = newArr.join(',');

         let queryParams = new HttpParams()
            .set('per_page', 250)
            .set('q', '*')
            .set('filter_by', `ticker:=[${filterStr}]`);

         await lastValueFrom(
            this.http
               .get<any>(
                  `${environment.typeSense.url}/collections/price_socket_collection/documents/search`,
                  { params: queryParams }
               )
               .pipe(
                  map((item) => {
                     const data: any = item.hits;
                     data.forEach((x: any) => {
                        const doc = x.document;

                        if (!doc.lastPrice) {
                           doc.lastPrice = doc.currentPrice;
                        }
                        doc.price = doc.currentPrice ?? doc.CurrentPrice;
                        doc.priceChange = doc.change
                           ? doc.change?.toFixed(2)
                           : doc.Change?.toFixed(2);
                        doc.priceChangePercent = doc.percentChange
                           ? doc.percentChange.toFixed(2)
                           : doc.percentChange.toFixed(2);
                        items[doc.ticker] = doc;
                     });
                     return items;
                  })
               )
         );
      }
      return items;
   }

   public getEarningSurprises(ticker: string) {
      return lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/earnings_surprises_collection/documents/${ticker}`
         )
      );
   }

   public getEarningEstimates(ticker: string) {
      return lastValueFrom(
         this.http
            .get<any>(
               `${environment.typeSense.url}/collections/earnings_estimates_collection/documents/${ticker}`
            )
            .pipe(
               map((item) => {
                  const items: any = {
                     data: [],
                  };
                  const data: any = item.data ?? item.Data;
                  data.forEach((x: any) => {
                     items.data.push({
                        epsAvg: x.epsAvg ?? x.EpsAvg,
                        epsHigh: x.epsHigh ?? x.EpsHigh,
                        epsLow: x.epsLow ?? x.EpsLow,
                        numberAnalysts: x.numberAnalysts ?? x.NumberAnalysts,
                        period: x.period ?? x.Period,
                        year: x.year ?? x.Year,
                        quarter: x.quarter ?? x.Quarter,
                     });
                  });
                  return items;
               })
            )
      );
   }

   public getEarningCalendars(ticker: string) {
      return lastValueFrom(
         this.http
            .get<any>(
               `${environment.typeSense.url}/collections/earnings_calendar_collection/documents/${ticker}`
            )
            .pipe(
               map((item) => {
                  const items: any = {
                     earningsCalendar: [],
                  };
                  const data: any =
                     item.earningsCalendar ?? item.EarningsCalendar;
                  data.forEach((x: any) => {
                     items.earningsCalendar.push({
                        date: x.date ?? x.Date,
                        epsActual: x.epsActual ?? x.EpsActual,
                        epsEstimate: x.epsEstimate ?? x.EpsEstimate,
                        hour: x.hour ?? x.Hour,
                        quarter: x.quarter ?? x.Quarter,
                        revenueActual: x.revenueActual ?? x.RevenueActual,
                        revenueEstimate: x.revenueEstimate ?? x.RevenueEstimate,
                        symbol: x.symbol ?? x.Symbol,
                        year: x.year ?? x.Year,
                     });
                  });
                  return items;
               })
            )
      );
   }

   public getRevenueEstimates(ticker: string) {
      return lastValueFrom(
         this.http
            .get<any>(
               `${environment.typeSense.url}/collections/revenue_estimates_collection/documents/${ticker}`
            )
            .pipe(
               map((item) => {
                  const items: any = {
                     data: [],
                  };
                  const data: any = item.data ?? item.Data;
                  data.forEach((x: any) => {
                     items.data.push({
                        lastUpdate: x.lastUpdate ?? x.LastUpdate,
                        numberAnalysts: x.numberAnalysts ?? x.NumberAnalysts,
                        period: x.period ?? x.Period,
                        revenueAvg: x.revenueAvg ?? x.RevenueAvg,
                        revenueHigh: x.revenueHigh ?? x.RevenueHigh,
                        revenueLow: x.revenueLow ?? x.RevenueLow,
                        year: x.year ?? x.Year,
                        quarter: x.quarter ?? x.Quarter,
                     });
                  });
                  return items;
               })
            )
      );
   }

   public getPriceTarget(ticker: string) {
      return lastValueFrom(
         this.http
            .get<any>(
               `${environment.typeSense.url}/collections/price_target_collection/documents/${ticker}`
            )
            .pipe(
               map((item) => {
                  return {
                     lastUpdated: item.lastUpdated ?? item.LastUpdated,
                     symbol: item.symbol ?? item.Symbol,
                     targetHigh: item.targetHigh ?? item.TargetHigh,
                     targetLow: item.targetLow ?? item.TargetLow,
                     targetMean: item.targetMean ?? item.TargetMean,
                     targetMedian: item.targetMedian ?? item.TargetMedian,
                     id: item.id ?? item.Id,
                  };
               })
            )
      );
   }

   public getInvestmentChecklistVariables() {
      return lastValueFrom(
         this.http
            .get<any>(
               `${environment.typeSense.url}/collections/investment_checklist_variables/documents/search?q=*&per_page=250`
            )
            .pipe(
               map((item) => {
                  const data: any = item.hits;
                  const items: Array<InvestmentChecklistVariableModel> = [];
                  data.forEach((x: any) => {
                     const doc: InvestmentChecklistVariableModel = x.document;
                     items.push(doc);
                  });
                  return items;
               })
            )
      );
   }

   async getMatketNews<T>(): Promise<T> {
      let queryParams = new HttpParams()
         .set('q', '*')
         .set('sort_by', 'Datetime:desc')
         .set('per_page', '10')
         .set('page', '1');

      return await lastValueFrom(
         this.http.get<T>(
            `${environment.typeSense.url}/collections/market_news_collection/documents/search`,
            { params: queryParams }
         )
      );
   }

   public async getSuperInvestors(
      sort_by: string,
      perPage: number,
      page: number
   ) {
      let queryParams = new HttpParams()
         .set('q', '*')
         .set('sort_by', sort_by)
         .set('per_page', perPage)
         .set('page', page);

      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/super_investors/documents/search`,
            { params: queryParams }
         )
      );
   }

   public async getSuperInvestorsPortfolio(
      cikId: string,
      perPage: number,
      sortBy: string,
      page: number
   ) {
      let queryParams = new HttpParams()
         .set('q', '*')
         .set('filter_by', `cik_id:${cikId}`)
         .set('per_page', perPage)
         .set('sort_by', sortBy)
         .set('page', page);

      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/super_investors_portfolio/documents/search`,
            { params: queryParams }
         )
      );
   }

   public async getHalalStockBuckets(
      isVisible: string,
      perPage: number,
      sortOrder: string
   ) {
      let queryParams = new HttpParams()
         .set('q', '*')
         .set('filter_by', isVisible)
         .set('per_page', perPage)
         .set('sort_by', sortOrder);

      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/halal_stock_buckets/documents/search`,
            { params: queryParams }
         )
      );
   }

   searchOnlyStockSymbols(name: string, stockCountries: any[] = []) {
      if (name === '') {
         return of([]);
      }

      const searches = [
         {
            collection: 'company_profile_collection',
            q: name,
            query_by: 'name,ticker',
            prioritize_token_position: true,
            sort_by: '_text_match:desc,tickerIsMain:desc,usdMarketCap:desc',
            query_by_weights: '1,2',
            filter_by: `status:=PUBLISH&&country:=[${stockCountries}]`,
         },
      ];

      return this.http
         .post<any>(environment.typeSense.url + '/multi_search', { searches })
         .pipe(
            map((item) => {
               const results: any = item?.results;
               const ret: any[] = [];

               results.forEach((item: any) => {
                  const data: any = item.hits;
                  if (data) {
                     this.processStockData(data, ret, name);
                  }
               });

               return ret.sort((a, b) => b.textMatch - a.textMatch);
            })
         );
   }

   async getInvestmentProgress() {
      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/investment_progressbar/documents/1`
         )
      );
   }

   async searchSuperInvestorStocks(
      searchQuery: string,
      cikId: string,
      perPage: number,
      sortBy: string,
      page: number
   ) {
      const query =
         searchQuery && searchQuery.trim() !== '' ? searchQuery : '*';

      let queryParams = new HttpParams()
         .set('q', query)
         .set('query_by', 'name,symbol')
         .set('filter_by', `cik_id:${cikId}`)
         .set('per_page', perPage)
         .set('sort_by', sortBy)
         .set('page', page);

      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/super_investors_portfolio/documents/search`,
            { params: queryParams }
         )
      );
   }

   async getSuperInvestorsByStocks(
      searchQuery: string,
      perPage: number,
      page: number
   ) {
      let queryParams = new HttpParams()
         .set('q', searchQuery)
         .set('query_by', 'symbol')
         .set('per_page', perPage)
         .set('page', page);

      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/super_investors_portfolio/documents/search`,
            { params: queryParams }
         )
      );
   }

   public async getSuperInvestorsDataFromIds(
      filter_by: any,
      per_page: number = 250
   ) {
      let queryParams = new HttpParams()
         .set('q', '*')
         .set('filter_by', `cik:=[${filter_by}]`)
         .set('per_page', per_page);

      return await lastValueFrom(
         this.http.get<any>(
            `${environment.typeSense.url}/collections/super_investors/documents/search`,
            { params: queryParams }
         )
      );
   }

   async getStocksComplianceInfo(symbols: string[]): Promise<any> {
      try {
         let complianceInfo = await this.getStockFromSymbols(symbols).then(
            (data: any) => {
               return Object.entries<{ [key: string]: any }>(data).map(
                  ([key, value]) => ({
                     symbol: key,
                     isin: value.isin,
                     name: value.name,
                     logo: value.logo ? value.logo : '',
                     country: value.country,
                     complianceRanking: value.compliantRanking ?? '',
                     complianceStatus: value.shariahCompliantStatus,
                     currency: value.currency,
                     usdMarketCap: value.usdMarketCap,
                     type: 'stock',
                  })
               );
            }
         );
         return complianceInfo;
      } catch (error) {
         console.error(error);
         throw error;
      }
   }

   async getEtfsComplianceInfo(symbols: any): Promise<any> {
      try {
         let complianceInfo = await this.getEtfFromSymbols(symbols).then(
            (data: any) => {
               return Object.entries<{ [key: string]: any }>(data).map(
                  ([key, value]) => ({
                     symbol: key,
                     isin: value.isin,
                     aum: value.aum,
                     name: value.name,
                     logo: value.logo ? value.logo : '',
                     // country: value.country,
                     complianceRanking: value.ranking ?? '',
                     complianceStatus: value.shariahStates,
                     currency: value.navCurrency,
                     type: 'etf',
                  })
               );
            }
         );
         return complianceInfo;
      } catch (error) {
         console.error(error);
         throw error;
      }
   }

   getWebsiteCollectionData(): Observable<WebsiteStats> {
      this.websiteStats$ = this.websiteStats$ ?? this.fetchWebsiteCollection().pipe(shareReplay());
      return this.websiteStats$;
   }

   private fetchWebsiteCollection(): Observable<WebsiteStats> {
      return this.http
         .get<WebsiteStats>( `${environment.typeSense.url}/collections/website_collection/documents/stats`, {} )
         .pipe(map((response) => response));
   }

   getPortfolioStats(): Observable<any>  {
      this.portfolioStats$ = this.portfolioStats$ ?? this.fetchPortfolioStats().pipe(shareReplay());
      return this.portfolioStats$;
   }

   private fetchPortfolioStats(): Observable<any> {
      return this.http
         .get<any>( `${environment.typeSense.url}/collections/website_collection/documents/portfolio`, {} )
         .pipe(map((response) => response));
   }
}
