import { Injectable, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { first, lastValueFrom } from 'rxjs';
import { AuthPopupComponent } from 'src/app/authentication/auth-popup/auth-popup.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
   providedIn: 'root',
})
export class AuthService {
  checking = false
  private token: string
  private role: string
  private userId: string
  private email: string
  private viewedCollections: string[] = []
  public roleUpdatedEvent = new EventEmitter<boolean>();

  private modalRef: NgbModalRef | undefined;

  constructor(
    private cookie:CookieService,
    private authFire:AngularFireAuth,
    private modalService: NgbModal
    ) {
    this.token = localStorage.getItem('token') || ''
    this.userId = localStorage.getItem('userId') || ''
    this.email = localStorage.getItem('email') || ''
    this.role = localStorage.getItem('role') || ''
    if (this.token) {
      this.checking = true
    } else {
      this.checking = false
    }
  }

  logout() {
   this.token = ''
   this.userId = ''
   this.role = ''
   this.email = ''
   this.checking = false;
   // console.log("removeAll()")
   this.cookie.removeAll();
   // localStorage.setItem('token', this.token)
   // localStorage.setItem('userId', this.userId)
   // localStorage.setItem('email', this.email)
   // localStorage.setItem('role', this.role)
   localStorage.clear()
   sessionStorage.clear()
   this.roleUpdatedEvent.emit(true)
 }

  openAuthPopup(action: string | null = null) {
    this.modalRef = this.modalService.open(AuthPopupComponent, {
      centered: true,
      scrollable: true,
      backdropClass: 'global-modal-backdrop',
      windowClass: 'global-modal'
    });
    if(action) {
      this.modalRef.componentInstance.action = action
    }
  }

  closeAuthPopup() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  async setToken(token: any): Promise<void> {
    this.token = token
    localStorage.setItem('token', token)
    // this.userId = response['userId']
    // this.email = response['email']
    // this.role = response['roles']
    // this.checking = true

    // localStorage.setItem('userId', this.userId)
    // localStorage.setItem('email', this.email)
    // localStorage.setItem('role', this.role)
    // await this.cookie.put('_authorization', `Bearer ${this.token}`, {secure: false, httpOnly: false, sameSite: 'lax'})
    // localStorage.setItem('cookie', 'true');

    // this.roleUpdatedEvent.emit(true)
  }

  async setUserData(response: any): Promise<void> {
    var rolesArr = response['roles']?.map((obj:any)=>{
      obj.name
    })

    var rolesArr = response['authorities'].map(function(authority: any){
        return authority.name;
    }).join(",")

    this.userId = response['identifier']
    this.email = response['email']
    this.checking = true
    localStorage.setItem('userId', this.userId)
    localStorage.setItem('email', this.email)
    this.setRole(rolesArr)
    await this.cookie.put('_authorization', `Bearer ${this.token}`, {secure: false, httpOnly: false, sameSite: 'lax'})
    localStorage.setItem('cookie', 'true');

    this.roleUpdatedEvent.emit(true)
  }

  storeToken(token:string){
    localStorage.setItem('token', token)
  }

  setLoggedIn(){
    localStorage.setItem('is_logged_in', '1')
  }


  setRole(role:string)
  {
    this.role = role;
    localStorage.setItem('role', this.role)
    this.roleUpdatedEvent.emit(true)
  }

  setViewedCollections(symbols: [])
  {
   this.viewedCollections = symbols
  }

  addViewedCollection(symbol: string)
  {
   this.viewedCollections.push(symbol)
  }

  deleteViewedCollections()
  {
   this.viewedCollections = []
  }

  getViewedCollections()
  {
      return this.viewedCollections
  }

  getToken(): string {
    return this.token
  }

  getUserId(): string {
    return this.userId
  }

  getEmail(): string {
    return this.email
  }

  isLoggedIn(): boolean {
    var data = localStorage.getItem('is_logged_in')
    return data == "1"
    // if(token)
    //   return true
    // var currentUser = await lastValueFrom(this.authFire.authState.pipe(first()))

    // return currentUser ? !currentUser.isAnonymous : false
  }

  getRole(): string {
    if (this.role) {
      let roles = this.role.split(',')
      return roles[roles.length - 1]
    } else {
      return ''
    }
  }

  checkRole(role:string):boolean
  {
    if(role == 'ANONYMOUS'){
      return !this.isLoggedIn()
    }
    else{
      if(this.isLoggedIn()){
        let roles = this.role?.split(',');
        return roles?.includes(role);
      }
      return false;
   }
   }
}
