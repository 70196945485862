import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorNotFoundComponent } from './shared/components/error-not-found/error-not-found.component';
import { CabinetGuard } from './shared/guard/cabinet.guard';
import { PagesGuard } from './shared/guard/pages.guard';

const routes: Routes = [
   {
      path: '',
      children: [
         {
            path: '',
            loadChildren: () =>
               import('./cabinet/cabinet.module').then((m) => m.CabinetModule),
            canActivate: [CabinetGuard],
         },
      ],
   },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
  {
     path: '',
     redirectTo: '',
     pathMatch: 'full',
  },
  {
    path: '',
    children: [
      {
        path: 'authentication',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      },
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'cabinet',
        loadChildren: () => import('./cabinet/cabinet.module').then(m => m.CabinetModule),
        canActivate: [CabinetGuard]
      },
    ]
  },
  {
   path: '',
   children: [
     {
       path: 'pages',
       loadChildren: () => import('./pages/pages.module').then(m => m.pagesModule),
       canActivate: [PagesGuard]
     },
   ]
 },

//  {
//   path: 'report',
//   component: ScreenerReportComponent
// },


   //  {
   //   path: 'report',
   //   component: ScreenerReportComponent
   // },
   { path: '**', component: ErrorNotFoundComponent },   
];

// user$ = this.activatedRoute.queryParams.pipe(map((q) => q['user']));

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
