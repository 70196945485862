import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class MusaffaRedirectService {
   constructor(
      private http: HttpClient,
      private router: Router,
      private subscriptionService: SubscriptionService,
      private route: ActivatedRoute
   ) {}

   async goToPage(module: string, recordId: string | null) {
      let path = '';
      let queryParams: any = {};

      if (module == 'home') {
         path = '/';
      } else if (module == 'stock_details') {
         if (recordId) {
            path = '/stock-overview/' + recordId;
         } else {
            path = '/';
         }
      } else if (module == 'etf_details') {
         if (recordId) {
            path = '/etf/' + recordId;
         } else {
            path = '/etf-screener';
         }
      } else if (module == 'purification') {
         path = '/portfolio-dashboard';
         queryParams = { state: 1, id: recordId };
      } else if (module == 'portfolio') {
         path = '/portfolio-dashboard';
         queryParams = { state: 0, id: recordId };
      } else if (module == 'stock_screener') {
         path = '/onboarding';
      } else if (module == 'etf_screener') {
         path = '/etf-srcreener';
      } else if (module == 'watchlist') {
         if (recordId) {
            path = '/watchlist-lists';
            queryParams = { id: recordId };
         } else {
            path = '/watchlist-lists';
         }
      } else if (module == 'public_list_details') {
         if (recordId) {
            path = '/list-details/' + recordId;
         } else {
            path = '/public-lists';
            queryParams = { lists: 'all' };
         }
      } else if (module == 'public_list') {
         path = '/public-lists';
         queryParams = { lists: 'all' };
      } else if (module == 'request_coverage') {
         path = '/requested-stocks';
      } else if (module == 'academy') {
         path = '/academy-articles';
      } else if (module == 'purification_calculator') {
         path = '/purification';
      } else if (module == 'zakat_calculator') {
         path = '/zakat-calculator';
      } else if (module == 'halal_collections') {
         path = '/overall-buckets';
         queryParams = { state: 0 };
      } else if (module == 'trending_halal_collections') {
         path = '/overall-buckets';
         queryParams = { state: 1 };
      } else if (module == 'super_investors') {
         path = '/super-investors';
      } else if (module == 'super_investor_details') {
         if (recordId) {
            path = '/super-investor-portfolio/' + recordId;
         }
         {
            path = '/super-investors';
         }
      } else if (module == 'paywall') {
         setTimeout(() => {
            this.subscriptionService.openPaywallModal();
         }, 2000);
      }
      if (path?.length) {
         this.router.navigate([path], {
            replaceUrl: true,
            relativeTo: this.route,
            queryParams: queryParams,
         });
      }
   }
}
