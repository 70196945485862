import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
   loadPermissions,
   loadPermissionsSuccess,
   loadPermissionsFailure,
} from './access-control.actions';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { FirebaseRemoteConfigService } from 'src/app/shared/services/firebase-remote-config.service';
import { Feature, PlanFeatureConfig } from 'src/app/models/permissions.model';

@Injectable()
export class PermissionsEffects {
   constructor(
      private actions$: Actions,
      private remoteConfigService: FirebaseRemoteConfigService
   ) {}

   loadPermissions$ = createEffect(() =>
      this.actions$.pipe(
         ofType(loadPermissions),
         mergeMap(() => {
            return from(
               this.remoteConfigService.getSubscriptionPlanConfig()
            ).pipe(
               map((response) => {
                  const role =
                     SubscriptionService.subscriptionData.value.newRole;
                  let permissions: any = {};
                  response.features.forEach((feature: Feature) => {
                     const role_feature_access =
                        feature.plan_feature_config.find(
                           (config: PlanFeatureConfig) => {
                              return config.plan_identifier === role;
                           }
                        );
                     if (role_feature_access?.access) {
                        permissions[feature.feature_key] = true;
                        if (role_feature_access?.max_access) {
                           permissions[feature.feature_key + '_count'] =
                              role_feature_access?.max_access;
                        }
                     } else {
                        permissions[feature.feature_key] = false;
                     }
                  });
                  return loadPermissionsSuccess({ permissions });
               }),
               catchError((error) => of(loadPermissionsFailure({ error })))
            );
         })
      )
   );
}
