import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TypesenseService } from './typesense.service';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root',
})
export class MarketUtilsService {
   constructor(
      // private typesenseService:TypesenseService,
      private http: HttpClient
      ) {}

   countries = []
   isFetched = false

   public async fetchCountries() {

      if(this.isFetched){
         return this.countries
      }
      else{
         try{
            let queryParams = new HttpParams().set('per_page', '250').set('q', '*');

            var countriesData =  await lastValueFrom(
               this.http.get<any>(
                  `${environment.typeSense.url}/collections/market_collection/documents/search`,
                  { params: queryParams }
               )
            );
            this.countries = countriesData.hits.map((obj:any)=>obj.document)
            this.isFetched = true
            return this.countries
         }
         catch(e){
            return null
         }

      }

   }

   public async fetchEnabledStockCountriesCode() {
      var data:any = await this.fetchCountries()
      return data.filter((obj:any)=>obj.stockEnabled).map((obj:any)=>obj.countryCode)
   }

   public async fetchEnabledEtfCountriesCode() {
      var data:any = await this.fetchCountries()
      return data.filter((obj:any)=>obj.etfEnabled).map((obj:any)=>obj.countryCode)
   }

   public async fetchByCountryCode(code:any) {
      var data:any = await this.fetchCountries()
      return data.find((obj:any)=>obj.countryCode === code)
   }

   // public async isCoveredCountry(code:string, type: string) {
   //    var data:any = await this.fetchCountries()
   //    return data.find((obj:any)=>obj.countryCode === code)
   // }


}
