import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserSubscriptionModel } from 'src/app/models/user-subscription.model';
import { Period, Role, SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { PaymentLinkResponse } from 'src/app/models/stripe-payment-link.model';
import { SubscriptionPlansResponse } from 'src/app/models/subscription-plans.model';
// import { PaywallPopupComponent } from '../components/paywall-popup/paywall-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CancelSubscriptionPopupComponent } from '../components/cancel-subscription-popup/cancel-subscription-popup.component';
import { ResumeSubscriptionPopupComponent } from '../components/resume-subscription-popup/resume-subscription-popup.component';
import { AnalyticsUtils } from 'src/app/shared/services/analytics-utils';
import { UserOnboardingServeComponent } from '../components/user-onboarding-serve/user-onboarding-serve.component';
import { Store } from '@ngrx/store';
import { loadPermissions } from 'src/app/stores/access-control/access-control.actions';
import { SharedService } from './shared.service';

@Injectable({
   providedIn: 'root',
})
export class SubscriptionService {
   constructor(
      private http: HttpClient,
      private modalService: NgbModal,
      private authService: AuthService,
      private store: Store,
      private analyticsUtils: AnalyticsUtils,
      private sharedService: SharedService
   ) {}

   public static subscriptionPlans =
      new BehaviorSubject<SubscriptionPlansResponse>({
         status: false,
         message: '',
         data: {
            year: {
               id: '',
               currency: '',
               price: 0,
               coupon: null,
               trial_info: {
                  can_trial: false,
                  trial_days: 0,
               },
            },
            month: {
               id: '',
               currency: '',
               price: 0,
               coupon: null,
               trial_info: {
                  can_trial: false,
                  trial_days: 0,
               },
            },
         },
      });

   public static stripeSubscriptionInfo = new BehaviorSubject<any>({});
   public static subscriptionFetched = new BehaviorSubject<boolean>(false);

   public static subscriptionData = new BehaviorSubject<SubscriptionInfoModel>({
      isDataFetched: false,
      isSubscribed: false,
      isCancelled: false,
      subscriptionSource: null,
      identifier: null,
      purchaseDate: null,
      expiresDate: null,
      role: 'ANONYMOUS',
      newRole: null,
      period: null
   });

   private async getUserSubscriptionInfo(): Promise<UserSubscriptionModel> {
      const userId = this.authService.getUserId();
      return await lastValueFrom(
         this.http.get<UserSubscriptionModel>(
            environment.revenueCat.v1.url + '/subscribers/' + userId
         )
      );
   }

   public async getUpdatePaymentLink(priceId: string) {
      return await lastValueFrom(
         this.http.post<PaymentLinkResponse>(
            environment.apiUrl + '/api/user/subscribe/update',
            {
               new_price_id: priceId,
            }
         )
      );
   }

   public async getPaymentLink(priceId: string) {
      return await lastValueFrom(
         this.http.post<PaymentLinkResponse>(
            environment.apiUrl + '/api/user/subscribe',
            {
               price_id: priceId,
            }
         )
      );
   }

   public async cancelSubscription() {
      return await lastValueFrom(
         this.http.get<any>(
            environment.apiUrl + '/api/user/subscription/cancel'
         )
      );
   }

   public async cancelSubscriptionWithPriceId(priceId: string) {
      return await lastValueFrom(
         this.http.post<any>(
            environment.apiUrl + '/api/user/subscription/cancel',
            {
               price_id: priceId
            }
         )
      );
   }

   public async cancelSubscriptionReason(
      cancellationReason: string,
      otherReason: string
   ) {
      return await lastValueFrom(
         this.http.post<any>(
            environment.apiUrl + '/api/user/subscription/cancel/reason',
            {
               cancellation_reason: cancellationReason,
               cancellation_other_reason: otherReason,
            }
         )
      );
   }

   public async setSubscriptionPlans() {
      if (this.authService.getUserId()?.length) {
         const res = await lastValueFrom(
            this.http.get<SubscriptionPlansResponse>(
               environment.apiUrl + '/api/plans'
            )
         );
         SubscriptionService.subscriptionPlans.next(res);
      }
      SubscriptionService.subscriptionFetched.next(true);
   }

   public clearSubscriptionInfo() {
      SubscriptionService.subscriptionData.next({
         isDataFetched: true,
         isSubscribed: false,
         subscriptionSource: null,
         identifier: null,
         purchaseDate: null,
         expiresDate: null,
         isCancelled: false,
         role: 'ANONYMOUS',
         newRole: null,
         period: null
      });
   }

   public openPaywallModal() {
      this.sharedService.openUpgradePopup()
      // this.analyticsUtils.paywall_displayed();
      // this.modalService.open(PaywallPopupComponent, {
      //    centered: true,
      //    modalDialogClass: 'paywall-popup-modal',
      //    size: 'xl',
      //    backdrop: 'static',
      // });
   }

   public openUserOnboardingModal() {
      this.modalService.open(UserOnboardingServeComponent, {
         centered: true,
         scrollable: true,
         modalDialogClass: 'paywall-popup-modal',
         size: 'xl',
      });
   }

   public openCancelModal(priceId: string | null = null) {
      const modal = this.modalService.open(CancelSubscriptionPopupComponent, {
         centered: true,
         backdrop: 'static',
         modalDialogClass: 'paywall-popup-modal',
      });
      modal.componentInstance.priceId = priceId;
   }

   public openResumeModal() {
      this.modalService.open(ResumeSubscriptionPopupComponent, {
         centered: true,
         backdrop: 'static',
         modalDialogClass: 'paywall-popup-modal',
      });
   }

   public async resumeSubscription() {
      return await lastValueFrom(
         this.http.get<any>(
            environment.apiUrl + '/api/user/subscription/resume'
         )
      );
   }

   public async setStripeSubscriptionInfo() {
      const response = await this.getStripeSubscriptionInfo();
      if (response.status) {
         SubscriptionService.stripeSubscriptionInfo.next(response.data);
      }
   }

   public async getStripeSubscriptionInfo() {
      return await lastValueFrom(
         this.http.get<any>(environment.apiUrl + '/api/user/subscription')
      );
   }

   public async setUserSubscriptionInfo() {
      if (this.authService.getUserId()) {
         const subscriptionInfo: UserSubscriptionModel =
            await this.getUserSubscriptionInfo();
         const subscriber = subscriptionInfo.subscriber;
         const subscriptions = subscriber.subscriptions;
         const currentDateTime = new Date();
         let role = 'FREEMIUM';
         let newRole: Role = 'free';
         let period: Period = null;
         let isSubscribed = false;
         let subscriptionSource = null;
         let identifier = null;
         let purchaseDate = null;
         let expiresDate = null;
         let isCancelled = false;
         const starterProdId =
            subscriber?.entitlements?.starter?.product_identifier;
         const premiumProdId =
            subscriber?.entitlements?.premium?.product_identifier;
         const premiumPlusProdId =
            subscriber?.entitlements?.premium_plus?.product_identifier;
         if(premiumPlusProdId) {
            const subscription = subscriptions[premiumPlusProdId];
            const expires_date = new Date(subscription.expires_date);
            if (subscription.unsubscribe_detected_at) {
               isCancelled = true;
            }
            if (
               !subscription.expires_date ||
               expires_date.getTime() > currentDateTime.getTime()
            ) {
               role = 'PREMIUM';
               newRole = 'premium_plus';
               isSubscribed = true;
               identifier = premiumPlusProdId;
               purchaseDate = subscription.purchase_date;
               expiresDate = subscription.expires_date;
            }
            if (isSubscribed && identifier) {
               subscriptionSource = subscriber.subscriptions[identifier].store;
            }
         }
         if (!isSubscribed && premiumProdId) {
            const subscription = subscriptions[premiumProdId];
            const expires_date = new Date(subscription.expires_date);
            if (subscription.unsubscribe_detected_at) {
               isCancelled = true;
            }
            if (
               !subscription.expires_date ||
               expires_date.getTime() > currentDateTime.getTime()
            ) {
               role = 'PREMIUM';
               newRole = 'premium';
               isSubscribed = true;
               identifier = premiumProdId;
               purchaseDate = subscription.purchase_date;
               expiresDate = subscription.expires_date;
            }
            if (isSubscribed && identifier) {
               subscriptionSource = subscriber.subscriptions[identifier].store;
            }
         }
         if (!isSubscribed && starterProdId) {
            const subscription = subscriptions[starterProdId];
            const expires_date = new Date(subscription.expires_date);
            if (subscription.unsubscribe_detected_at) {
               isCancelled = true;
            }
            if (
               !subscription.expires_date ||
               expires_date.getTime() > currentDateTime.getTime()
            ) {
               role = 'PREMIUM';
               newRole = 'starter';
               isSubscribed = true;
               identifier = starterProdId;
               purchaseDate = subscription.purchase_date;
               expiresDate = subscription.expires_date;
            }
            if (isSubscribed && identifier) {
               subscriptionSource = subscriber.subscriptions[identifier].store;
            }
         }
         if(purchaseDate && expiresDate) {
            const purchaseDateObj = new Date(purchaseDate);
            const expiresDateObj = new Date(expiresDate);
            const timeDifference = expiresDateObj.getTime() - purchaseDateObj.getTime();
            const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
            period = (daysDifference >= 365) ? 'yearly' : 'monthly';
         }
         SubscriptionService.subscriptionData.next({
            isDataFetched: true,
            isSubscribed,
            subscriptionSource,
            identifier,
            purchaseDate,
            expiresDate,
            isCancelled,
            role,
            newRole,
            period
         });
         this.store.dispatch(loadPermissions());
         // for (let key in subscriptions) {
         //    const subscription = subscriptions[key];
         //    const expires_date = new Date(subscription.expires_date);
         //    if (subscription.unsubscribe_detected_at) {
         //       isCancelled = true;
         //    }
         //    if (
         //       !subscription.expires_date ||
         //       expires_date.getTime() > currentDateTime.getTime()
         //    ) {
         //       role = 'PREMIUM';
         //       isSubscribed = true;
         //       identifier = key;
         //       purchaseDate = subscription.purchase_date;
         //       expiresDate = subscription.expires_date;
         //       break;
         //    }
         // }
         // if (isSubscribed && identifier) {
         //    subscriptionSource = subscriber.subscriptions[identifier].store;
         // }
         // SubscriptionService.subscriptionData.next({
         //    isSubscribed,
         //    subscriptionSource,
         //    identifier,
         //    purchaseDate,
         //    expiresDate,
         //    role,
         // });
      } else {
         this.clearSubscriptionInfo();
      }
   }
}
