import { EventEmitter, Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class StripeFreeServices {

   StripeFreeStatus: EventEmitter<number|null>

   constructor() {
     this.StripeFreeStatus = new EventEmitter()
   }

   show(errorCode:number) {
     this.StripeFreeStatus.emit(errorCode)
   }

   hide() {
     this.StripeFreeStatus.emit(null)
   }


 }
