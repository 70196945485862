<div class="modal-body p-0">
   <section class="upgrade-grid">
      <button type="button" class="btn shadow-none p-1 bg-transparent rounded-3 modal-header-close-btn" aria-label="Close" (click)="activeUpgradeModal.close()">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7e7e7e" fill="none">
            <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
         </svg>
      </button>

      <div class="feature-images-wrapper overflow-hidden">
         <div class="d-lg-none">
            <img src="/assets/images/upgrade/Pro.png" class="img-fluid premium-logo" alt="Premium Logo">
            <h3 class="upgrade-heading fw-600">
               Unlock the full potential of Musaffa
            </h3>
         </div>
         
         <div class="w-80 ratio mx-auto ratio-1x1">
            <img
               [hidden]="activefeature !== 'report'"
               @fade
               appLazyLoadImage="/assets/images/upgrade/screener.png"
               placeholder="/assets/images/upgrade/feature-lowres.png"
               alt="feature image"
               class="feature-image"
            />
            <img
               [hidden]="activefeature !== 'portfolio'"
               @fade
               appLazyLoadImage="/assets/images/upgrade/portfolio.png"
               placeholder="/assets/images/upgrade/feature-lowres.png"
               alt="feature image"
               class="feature-image"
            />
            <img
               [hidden]="activefeature !== 'buySell'"
               @fade
               appLazyLoadImage="/assets/images/upgrade/buy-sell.png"
               placeholder="/assets/images/upgrade/feature-lowres.png"
               alt="feature image"
               class="feature-image"
            />
            <img
               [hidden]="activefeature !== 'brokerage'"
               @fade
               appLazyLoadImage="/assets/images/upgrade/brokerages.png"
               placeholder="/assets/images/upgrade/feature-lowres.png"
               alt="feature image"
               class="feature-image"
            />
         </div>

         <div class="carousel-dots">
            <span *ngFor="let feature of features; let i = index" (click)="setActiveFeature(feature)" [class.active]="feature === activefeature" ></span>
         </div>
          
      </div>
      
      <div class="upgrade-btns">
         <div class="d-none d-lg-block">
            <img src="/assets/images/upgrade/Pro.png" class="img-fluid premium-logo" alt="Premium Logo">
            <h3 class="upgrade-heading fw-700">
               Unlock the full potential of Musaffa
            </h3>
         </div>

         <ul class="d-grid gap-2 gap-lg-3 feature-points-list" role="list">
            <li class="d-flex align-items-center gap-2 gap-lg-3 rounded-pill overflow-hidden feature-points-item" (click)="setActiveFeature('report')" [ngClass]="activefeature === 'report' ? 'active-item' : ''" >
              <img src="/assets/icons/gradient-tick-icon.svg" class="img-fluid tick-icon" alt="tick-icon" />
              <p class="mb-0">Find out why a stock is Halal or Not Halal!</p>
            </li>
            <li class="d-flex align-items-center gap-2 gap-lg-3 rounded-pill overflow-hidden feature-points-item" (click)="setActiveFeature('portfolio')" [ngClass]="activefeature === 'portfolio' ? 'active-item' : ''" >
              <img src="/assets/icons/gradient-tick-icon.svg" class="img-fluid tick-icon" alt="tick-icon" />
              <p class="mb-0">Screen your portfolio & start making it shariah compliant</p>
            </li>
            <li class="d-flex align-items-center gap-2 gap-lg-3 rounded-pill overflow-hidden feature-points-item" (click)="setActiveFeature('buySell')" [ngClass]="activefeature === 'buySell' ? 'active-item' : ''" >
              <img src="/assets/icons/gradient-tick-icon.svg" class="img-fluid tick-icon" alt="tick-icon" />
              <p class="mb-0">Buy & Sell via supported brokerages</p>
            </li>
            <li class="d-flex align-items-center gap-2 gap-lg-3 rounded-pill overflow-hidden feature-points-item" (click)="setActiveFeature('brokerage')" [ngClass]="activefeature === 'brokerage' ? 'active-item' : ''" >
              <img src="/assets/icons/gradient-tick-icon.svg" class="img-fluid tick-icon" alt="tick-icon" />
              <p class="mb-0">Purify your investments</p>
            </li>
          </ul>
         
         <button type="button" class="gradient-btn btn shadow-none py-2 d-flex align-items-center justify-content-center px-4 mt-4 rounded-3 pricing-btn text-white" (click)="navigateToPricing()">
            View Pricing Plans
         </button>
      </div>
   </section>
</div>



