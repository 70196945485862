import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderServices } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  public show: boolean = false;

  public subscriptions:Subscription[];

  constructor(private loaderService: LoaderServices) {
    setTimeout(() => {
      this.show = false;
    }, 1500);
    this.subscriptions=[]
  }

  ngOnInit() {
    this.subscriptions = [];

    let subscription = this.loaderService.loaderStatus.subscribe((status:boolean)=>
    {
      this.show = status;
    });

    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    if(this.subscriptions && this.subscriptions.length > 0)
    {
      this.subscriptions.forEach(subscription => {
        subscription.unsubscribe();
      });
    }
  }

}
