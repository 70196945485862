import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appLazyLoadImage]'
})
export class LazyLoadImageDirective implements OnInit {
   @Input() appLazyLoadImage!: string; // The actual image URL
   @Input() placeholder!: string; // The placeholder image URL
   @Input() background: boolean = false; // If true, it will handle background images
   @Input() srcset?: string; // For responsive images

   private observer!: IntersectionObserver;

   constructor(private el: ElementRef, private renderer: Renderer2) {}

   ngOnInit() {
      this.initPlaceholder();
      this.initIntersectionObserver();
   }

   private initPlaceholder() {
      if (this.background) {
         this.renderer.addClass(this.el.nativeElement, 'bg-placeholder');
         this.renderer.setStyle(this.el.nativeElement, 'backgroundImage', `url(${this.placeholder})`);
      } else {
         this.renderer.setAttribute(this.el.nativeElement, 'src', this.placeholder);
      }
   }

   private initIntersectionObserver() {
      this.observer = new IntersectionObserver(entries => {
         entries.forEach(entry => {
         if (entry.isIntersecting) {
            this.loadImage();
            this.observer.unobserve(this.el.nativeElement);
         }
         });
      });

      this.observer.observe(this.el.nativeElement);
   }

   private loadImage() {
      if (this.background) {
         this.renderer.setStyle(this.el.nativeElement, 'backgroundImage', `url(${this.appLazyLoadImage})`);
      } else {
         if (this.srcset) {
         this.renderer.setAttribute(this.el.nativeElement, 'srcset', this.srcset);
         }
         this.renderer.setAttribute(this.el.nativeElement, 'src', this.appLazyLoadImage);
      }
   }
}