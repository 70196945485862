import { EventEmitter, Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class LoaderServices
{
  private count:number = 0;

  loaderStatus: EventEmitter<boolean>

  constructor() {
    this.loaderStatus = new EventEmitter()
  }

  show() {
    this.loaderStatus.emit(true)
    this.count ++;
  }

  hide() {
   console.log('hide called')
    this.count --;
    // if(this.count <= 0 ){
    //   this.loaderStatus.emit(false)
    // }
    this.loaderStatus.emit(false)

  }

  reset()
  {
   console.log('reset called')
    this.loaderStatus.emit(false);
    this.count = 0;

  }

}
